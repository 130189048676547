<template>
<div></div>
</template>
<script>
import LiffChecker from "@/utils/LiffChecker";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";
import { mapActions } from 'vuex';

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    const query = this.$route.query;
    this.setQuery(
      {
        phone: query.phone,
        source: query.source,
        coupon_code: query.coupon_code,
        qrcode: query.qrcode,
        store_code: query.store_code,
        brand: query.brand,
      },
      this.$route.params.couponType
    )
    const checker = new LiffChecker(this.$route.params.orgCode);
    this.lineData = await checker.getLineData();
    await this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {},
  methods: {
    ...mapActions('projAbbottAnd', ['setQuery']),
    async init() {
      await this.checkMember(this.lineData.profile.userId)
    },
  }
}
</script>

<style lang="scss"></style>
