import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapGetters } from "vuex";

export default {
  methods: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
    }),
    async submit(lineId) {
      try {
        await abbottAndApi.sendCoupon(
          this.$route.params.orgCode,
          {
            ...this.queryData,
            line_id: lineId,
          }
        )
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: "成功領取",
          },
        });
      } catch (e) {
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: e.response.data.message,
          },
        });
      }
    },
    async checkMember(lineId) {
      switch (this.couponType) {
        case "j5":
          try {
            const res = await abbottAndApi.checkMemberByLine(
              this.$route.params.orgCode,
              {
                line_id: lineId,
              }
            );
            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode);
            }
          } catch (e) {
            alert(e.response.data.message);
          }
          break;
        default:
          try {
            const res = await abbottAndApi.checkMemberByPhone(
              this.$route.params.orgCode,
              {
                phone: this.queryData.phone,
              }
            );
            if (res.data.is_member) {
              if (res.data.line_id == lineId) {
                this.submit(lineId);
              } else {
                alert("此手機與查詢會員不一致，請聯絡客服人員");
              }
            } else {
              this.submit(lineId);
            }
          } catch (e) {
            console.log(e);
            alert(e.response.data.message);
          }
          break;
      }
    },
  },
};
